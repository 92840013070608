import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Amplify, Auth } from "aws-amplify";
import posthog from "posthog-js";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import relayTheme from "./css/authTheme";
import Header from "./components/Header";
import Home from "./components/Home";
import Settings from "./components/Settings";
import UserInvitation from "./components/UserInvitation";
import PublicRoles from "./components/PublicRoles";
import PublicCandidates from "./components/PublicCandidates";
import Candidates from "./components/Candidates";
import Roles from "./components/Roles";
import Messages from "./components/Messages";
import AddToProject from "./components/JobAdder/AddToProject";
import { JobAdderCallback } from "./components/JobAdder";
import { VincereCallback } from "./components/Vincere";
import config from "./config";
import Dashboard from "./components/Dashboard";
import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import Dispute from "./components/Dispute";
import Join from "./components/Join";
import logo from "./assets/green-logo.svg";
import rcsaLogo from "./assets/rcsa.png";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { GET_CONNECTIONS, GET_PROFILE } from "./GraphQL/settings/queries";
import PublicMatches from "./components/PublicMatches";
import NewCompany from "./components/NewCompany";
import NewCompanyUser from "./components/NewCompanyUser";

export const ProfileContext = React.createContext({
  connections: {} as any,
  userProfile: {} as any,
});

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const CustomAuthHeader = styled.div`
  text-align: center;
  font-family: "Barlow", sans-serif !important;
  margin: 40px 0 40px 0;
  & img {
    width: 70%;
  }
`;
const CustomAuthFooter = styled.div`
  text-align: center;
  font-family: "Barlow", sans-serif !important;
  font-size: 0.9rem;
`;

const DownMaintenance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

Amplify.configure(config.authConfig);

const tokenFetch = async (uri: string, options: RequestInit) => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const headers = new Headers(options.headers);
    headers.set("Authorization", `Bearer ${token}`);
    options.headers = headers;
    return fetch(uri, options);
  } catch (error) {
    console.log("App session error", error);
    return fetch(uri, options);
  }
};

const client = new ApolloClient({
  link: new HttpLink({
    uri: config.apiUrl,
    fetch: tokenFetch,
    credentials: "include",
  }),
  cache: new InMemoryCache(),
});

const App = (): JSX.Element => {
  const [connections, setConnections] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const showMatchesFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showMatchesPage
  );
  const isMaintenanceFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.isDownMaintenance
  );
  const isDashboardFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showDashboard
  );
  const showHeaderFooter = !window.location.pathname.includes("ja-action");

  const getProfile = async () => {
    try {
      const { data } = await client.query({ query: GET_CONNECTIONS });
      setConnections(data.connections);
    } catch (error) {
      console.log("Error getting connections", error);
    }
    try {
      const { data } = await client.query({ query: GET_PROFILE });
      setUserProfile(data.profile);
    } catch (error) {
      console.log("Error getting profile", error);
    }
  };

  const getSession = async () => {
    const session = await Auth.currentSession();
    if (session?.getIdToken()?.payload?.email) {
      posthog.identify(`${session.getIdToken().payload.sub}`, {
        email: session.getIdToken().payload.email,
        name: session.getIdToken().payload.name,
        agency: session.getIdToken().payload.agency,
      });

      getProfile();
    }
  };

  useEffect(() => {
    getSession();
    getProfile();
  }, []);

  if (isMaintenanceFlag) {
    return (
      <DownMaintenance>
        <h3>
          Role Relay is currently offline for system maintenance. Please check
          back soon.
        </h3>
      </DownMaintenance>
    );
  }

  const components = {
    Header() {
      return (
        <CustomAuthHeader>
          <img src={logo} alt="Role Relay" />
        </CustomAuthHeader>
      );
    },
    Footer() {
      return (
        <CustomAuthFooter>
          <br />
          <div>In partnership with</div>
          <img src={rcsaLogo} width={180} alt="RCSA logo" />
          <br />
          <br />
          &copy; 2024 - Role Relay Pty Ltd
        </CustomAuthFooter>
      );
    },
  };

  return (
    <ThemeProvider theme={relayTheme}>
      <ProfileContext.Provider
        value={{ connections: connections, userProfile: userProfile }}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/invitation"
              element={
                <ApolloProvider client={client}>
                  <UserInvitation />
                </ApolloProvider>
              }
            />
            <Route path="/new-company" element={<NewCompany />} />
            <Route
              path="/new-company-user"
              element={<NewCompanyUser user={userProfile} />}
            />
            <Route path="/join" element={<Join user={userProfile} />} />
            <Route
              path="*"
              element={
                <Authenticator hideSignUp={true} components={components}>
                  {({ signOut, user }: any) => (
                    <Main>
                      <ApolloProvider client={client}>
                        {showHeaderFooter && (
                          <Header signOut={signOut} user={user} />
                        )}
                        <Routes>
                          <Route
                            path="/"
                            element={isDashboardFlag ? <Dashboard /> : <Home />}
                          />
                          <Route
                            path="/public-roles"
                            element={<PublicRoles user={user} />}
                          />
                          <Route
                            path="/public-roles/:roleId"
                            element={<PublicRoles user={user} />}
                          />
                          <Route
                            path="/partner-roles"
                            element={
                              <PublicRoles
                                user={user}
                                initialActiveTab="partner"
                              />
                            }
                          />
                          <Route
                            path="/partner-roles/:roleId"
                            element={
                              <PublicRoles
                                user={user}
                                initialActiveTab="partner"
                              />
                            }
                          />
                          <Route
                            path="/public-candidates"
                            element={<PublicCandidates user={user} />}
                          />
                          <Route
                            path="/public-candidates/:candidateId"
                            element={<PublicCandidates user={user} />}
                          />
                          <Route
                            path="/partner-candidates"
                            element={
                              <PublicCandidates
                                user={user}
                                initialActiveTab="partner"
                              />
                            }
                          />
                          <Route
                            path="/partner-candidates/:candidateId"
                            element={
                              <PublicCandidates
                                user={user}
                                initialActiveTab="partner"
                              />
                            }
                          />
                          <Route
                            path="/project-candidates"
                            element={
                              <PublicCandidates
                                user={user}
                                initialActiveTab="projects"
                              />
                            }
                          />

                          <Route
                            path="/project-candidates/:candidateId"
                            element={
                              <PublicCandidates
                                user={user}
                                initialActiveTab="projects"
                              />
                            }
                          />
                          <Route
                            path="/my-roles"
                            element={<Roles user={user} />}
                          />
                          <Route
                            path="/my-roles/:roleId"
                            element={<Roles user={user} />}
                          />
                          <Route
                            path="/my-roles/:roleId/:matchId"
                            element={<Roles user={user} />}
                          />
                          <Route
                            path="/my-candidates"
                            element={<Candidates user={user} />}
                          />
                          <Route
                            path="/my-candidates/:candidateId"
                            element={<Candidates user={user} />}
                          />
                          <Route
                            path="/my-candidates/:candidateId/:matchId"
                            element={<Candidates user={user} />}
                          />
                          <Route
                            path="/matches"
                            element={
                              showMatchesFlag && <PublicMatches user={user} />
                            }
                          />
                          <Route
                            path="/matches/:matchId"
                            element={
                              showMatchesFlag && <PublicMatches user={user} />
                            }
                          />
                          <Route
                            path="/ja-action/:action/:id"
                            element={<AddToProject user={user} />}
                          />
                          <Route path="/settings" element={<Settings />} />
                          <Route
                            path="/settings/:pathUrl"
                            element={<Settings />}
                          />
                          <Route
                            path="/messages"
                            element={<Messages user={user} activeTab="roles" />}
                          />
                          <Route
                            path="/messages/roles"
                            element={<Messages user={user} activeTab="roles" />}
                          />
                          <Route
                            path="/messages/candidates"
                            element={
                              <Messages user={user} activeTab="candidates" />
                            }
                          />
                          <Route
                            path="/messages/:topicType/:topicId"
                            element={<Messages user={user} />}
                          />
                          <Route path="/privacy" element={<Privacy />} />
                          <Route path="/dispute" element={<Dispute />} />

                          <Route
                            path="/connections/jobadder/callback"
                            element={<JobAdderCallback />}
                          />
                          <Route
                            path="/connections/vincere/callback"
                            element={<VincereCallback />}
                          />
                        </Routes>
                        {showHeaderFooter && <Footer />}
                      </ApolloProvider>
                    </Main>
                  )}
                </Authenticator>
              }
            />
          </Routes>
        </BrowserRouter>
      </ProfileContext.Provider>
    </ThemeProvider>
  );
};

export default App;
