import { useContext } from "react";
import { ProfileContext } from "../../App";
import { Card } from "react-bootstrap";
import styled from "styled-components";

const DashboardContainer = styled(Card)`
  margin: 0 1rem;
`;

const Dashboard = () => {
  const profile = useContext(ProfileContext);

  return (
    <DashboardContainer>
      <Card.Body>Welcome <strong>{profile?.userProfile?.firstName}</strong> on new dashboard page.</Card.Body>
    </DashboardContainer>
  )
}

export default Dashboard;
